import { NgClass, NgTemplateOutlet } from '@angular/common';
import { AfterContentInit, Component, ContentChild, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, debounceTime, filter, shareReplay, switchMap, takeUntil } from 'rxjs';
import { environment } from '../../_environments/environment';
import { Orderline } from '../../_model/order';
import { Allergen, Recipe } from '../../_model/recipe';
import { TableParameters, UrlParameters } from '../../_model/shared';
import { RecipeService } from '../../_service/RecipeService';
import { WINDOW, windowProvider } from '../../_service/WindowService';
import { LoaderComponent } from '../loader/loader.component';
import { PlaceholderComponent } from '../placeholder/placeholder.component';


@Component({
  selector: 'recipe-list',
  templateUrl: './recipelist.component.html',
  styleUrls: ['./recipelist.component.scss'],
  standalone: true,
  providers: [windowProvider],
  imports: [
    NgClass, NgTemplateOutlet,
    LoaderComponent, PlaceholderComponent,
    TranslateModule
  ]
})
export class RecipelistComponent implements OnInit, AfterContentInit, OnDestroy {

  environment = environment;
  container_class = '';
  scrollPosition = 0;
  scrollEnd = false;

  _lifestyleids: number[];
  @Input() cols = 3;
  @Input() set lifestyles(value: number[]) {
    this._lifestyleids = value ? value.filter(v => v) : []
    if (!this._deliverydate || this._deliverydate == '') {
      this.shownRecipes = this.filterOnLifestyles(this._allRecipes);
      this.shownRecipesChange.emit(this.shownRecipes);
    } else {
      this.shownRecipes = [];
      this.addRecipesToShow(this._allRecipes)

      
      // this.shownRecipesChange.emit(this.shownRecipes);
      // this.currentShownPage = null;
      // this.shownPage = 0;
      // this.totalPages = 1;


      // const tblparams = new TableParameters(this.shownPage, this.pagesize, 'id', 'asc');
      // const params = tblparams.getUrlParameters();
      // this.recipeSearch.next(params);
    }
  }
  _allergens: Allergen[];
  @Input() set allergens(value: Allergen[]) {
    this._allergens = value;
    this.shownRecipes = this.sortRecipes(this.shownRecipes);
  }
  _deliverydate: string;
  @Input() set deliverydate(value: string) {
    this._deliverydate = value;
    if (value) {
      this.set_ordered_recipes_to_start = true;
      this.shownRecipes = [];
      this._allRecipes = [];
      this._searchRecipes = [];
      this.currentShownPage = null;
      this.shownPage = 0;
      this.totalPages = 1;
      this.loadMoreRecipes(false)
    }
  }
  _orderlines: Orderline[]
  @Input() set orderlines(value: Orderline[]) {
    this._orderlines = value;
    if (this._orderlines) {
      const recipeids = this._orderlines.filter(o=> o.recipeid).map(a => a.recipeid);
      this.getOrderRecipelist.next(recipeids);
    }else{
      this.getOrderRecipelist.next([]);
    }
  }
  @Input() set recipes(value: Recipe[]) {

    if (value) {
      this._allRecipes = [...new Map([...this._allRecipes, ...value].map(item => [item['id'], item])).values()];
      this.addRecipesToShow(value)
      // const filteredRecipes = this.filterOnLifestyles(value)
      // if (filteredRecipes) {
      //   const addedRecipes = this.sortRecipes(filteredRecipes.filter(f => !this.shownRecipes.map(r => r.id).includes(f.id)))
      //   this.shownRecipes = [...new Map([
      //     ...this.shownRecipes, 
      //     ...addedRecipes
      //   ].map(item => [item['id'], item])).values()];
      // }
      // this.shownRecipesChange.emit(this.shownRecipes);
    } else {
      this._allRecipes = [];
      this.shownRecipes = [];
      this.shownRecipesChange.emit(this.shownRecipes);
    }

  }
  @Output() scrollloadChange = new EventEmitter<boolean>();
  @Input() scrollload = true;
  @Input() pagesize = 10;
  @Input() horizontal = false;
  @Input() condensed = false; //used for loading placeholder

  @Input() loading: boolean = false;
  @Input() showallbtn: boolean = true;
  @Input() class: string;

  @Output() recipesChange = new EventEmitter<Recipe[]>();
  @Output() shownRecipesChange = new EventEmitter<Recipe[]>();

  currentShownPage: number = null;
  shownPage: number = 0;
  totalPages: number = 1;
  lifestyleFilter = true;

  shownRecipes: Recipe[] = []; //a list of all shown recipes -> filtered on lifestyle
  _allRecipes: Recipe[] = []; // total list of all recipes
  _searchRecipes: Recipe[] = []; // is for keeping the list of searched recipes clean of recipes which are added by the orderlines input
  _orderRecipes: Recipe[] = []; //keeps a list of all ordered recipes
  set_ordered_recipes_to_start = false;

  error_recipes = false;

  private recipeSearch: Subject<UrlParameters> = new Subject();
  private getOrderRecipelist: Subject<number[]> = new Subject();
  private unsubscribe = new Subject<void>();
  @ViewChild('recipelist') recipelistEl: ElementRef;
  @ContentChild('recipe', { read: TemplateRef }) recipeTemplate: TemplateRef<any>;

  constructor(
    private _recipeService: RecipeService,
    @Inject(WINDOW) private window: Window,
  ) {
    this.recipeSearch.pipe(
      shareReplay(),
      filter(() => { return (this.shownPage <= this.totalPages) && (this.currentShownPage !== this.shownPage) && this._deliverydate && this._deliverydate != "" }),
      debounceTime(100),
      switchMap(tblparams => {
        this.currentShownPage = this.shownPage
        this.loading = true;
        return this._recipeService.getRecipeTableByPeriod(tblparams as UrlParameters, this._deliverydate, this._deliverydate);
      }),
      takeUntil(this.unsubscribe)
    ).subscribe(
      result => {
        this.error_recipes = false;
        if (!result) { return; }
        this.shownPage++;
        this.totalPages = result.totalPages;
        
        //sorts next batch of recipes and adds them to the total list while removing duplicates
        const recipes = this.sortRecipes(result.content);
        if (recipes) {
          this._searchRecipes = [...new Map([...this._searchRecipes, ...recipes].map(item => [item['id'], item])).values()];
          this._allRecipes = [...new Map([...this._allRecipes, ...recipes].map(item => [item['id'], item])).values()];
        }
        this.recipesChange.emit(this._allRecipes);
        this.addRecipesToShow(recipes)
        
        this.loading = false;
      }, error => {
        this.loading = false;
        this.error_recipes = true;
      })


    this.getOrderRecipelist.pipe(
      shareReplay(),
      switchMap(recipeids => {
        this.loading = true;
        return this._recipeService.getRecipeList(recipeids)
      }),
      takeUntil(this.unsubscribe)
    ).subscribe(result => {
      this.loading = false;

      const removedOrderlineRecipes = this._orderRecipes.filter(recipe => !result.map(r=> r.id).includes(recipe.id))
      this.removeNotAvailableOrderlineRecipes(removedOrderlineRecipes)

      this._orderRecipes = this.sortRecipesOnExpiration(result);
      this._allRecipes = [...new Map([...this._searchRecipes, ...result].map(item => [item['id'], item])).values()];
      this.recipesChange.emit(this._allRecipes);
      
      if (this.set_ordered_recipes_to_start) {
        this.shownRecipes = [...new Map([
          ...this._orderRecipes,
          ...this.shownRecipes, 
        ].map(item => [item['id'], item])).values()];
      }
      this.set_ordered_recipes_to_start = false;
      this.shownRecipesChange.emit(this.shownRecipes);

    }, error => { this.loading = false; })

  }

  ngOnInit(): void {
    this.window.addEventListener('scroll', this.onScroll, true);
    if (!this.horizontal) {
      this.container_class = 'h-full grid grid-cols-[repeat(auto-fill,minmax(320px,_1fr))] gap-[25px] mb-32 md:mb-auto';
    } else {
      this.container_class = 'mb-[50px] md:mb-0 relative w-full flex gap-[25px] snap-x snap-proximity overflow-x-auto';
    }

  }
  ngAfterContentInit() {
    if (!this.recipeTemplate) {
      console.error('There is no recipetemplate given: <ng-template #recipe let-recipe></ng-template>')
    }
  }
  ngOnDestroy() {
    this.window.removeEventListener('scroll', this.onScroll, true);
    this.unsubscribe.next();
  }

  // for infinite scroll behaviour
  onScroll = (event: any): void => {
    if (this.recipelistEl && this.scrollload) {

      if (this.horizontal) {
        this.scrollPosition = this.recipelistEl.nativeElement.scrollLeft;
        this.setScrollEnd();
        const scrollLeftMax = this.recipelistEl.nativeElement.scrollWidth - this.recipelistEl.nativeElement.clientWidth
        const leftSpace = scrollLeftMax - this.recipelistEl.nativeElement.scrollLeft;
        if (leftSpace <= 500)
          this.loadMoreRecipes();

      } else {
        const bottomspace = this.recipelistEl.nativeElement.getBoundingClientRect();
        const viewportheight = this.window.innerHeight;
        if (bottomspace.top == 0 && bottomspace.bottom == 0)
          return;
        if (bottomspace.bottom - viewportheight < 500) {
          this.loadMoreRecipes()
        }
      }
    }
  }
  setScrollEnd() {
    if (!this.recipelistEl) return
    if (this.recipelistEl.nativeElement.offsetWidth + this.recipelistEl.nativeElement.scrollLeft >= this.recipelistEl.nativeElement.scrollWidth) {
      this.scrollEnd = true;
    } else {
      this.scrollEnd = false;
    }
  }

  loadMoreRecipes(onScroll = true) {
    if (this._deliverydate) {
      const tblparams = new TableParameters(this.shownPage, this.pagesize, 'id', 'asc');
      const params = tblparams.getUrlParameters();
      this.recipeSearch.next(params);
    }
    if(this.onScroll)
      this.scrollloadChange.emit(true);
  }
  showAllRecipes() {
    this.lifestyleFilter = false;
    this.loading = true;

    let addedrecipes = [];
    this._allRecipes.forEach(r => {
      if (this.shownRecipes.map(c => c.id).indexOf(r.id) === -1) {
        addedrecipes.push(r);
      }
    });

    addedrecipes = this.sortRecipes(addedrecipes);

    this.shownRecipes = [...new Map([...this.shownRecipes, ...addedrecipes].map(item => [item['id'], item])).values()];
    this.shownRecipesChange.emit(this.shownRecipes);

    this.loading = false;
  }
  showLessRecipes() {
    this.lifestyleFilter = true;
    this.loading = true;
    this.shownRecipes = this.filterOnLifestyles(this._allRecipes);
    this.shownRecipesChange.emit(this.shownRecipes);
    this.loading = false;
  }
  addRecipesToShow(recipes: Recipe[]){
    let filteredRecipes = this.filterOnLifestyles(recipes);
    if (filteredRecipes) {
      this.shownRecipes = [...new Map([
        ...(this.set_ordered_recipes_to_start ?this._orderRecipes: []) ,
        ...(this.shownRecipes? this.shownRecipes : []), 
        ...filteredRecipes
      ].map(item => [item['id'], item])).values()];
    }
    if(this._orderRecipes.length > 0) this.set_ordered_recipes_to_start =false;
    
    this.shownRecipesChange.emit(this.shownRecipes);
    this.setScrollEnd();

    //loads more recipes if there are less than 4 recipes
    if (this.shownRecipes && this.shownRecipes.length < 4 && this.shownPage <= this.totalPages + 1) {
      this.loadMoreRecipes();
    }

  }

  removeNotAvailableOrderlineRecipes(removedOrderlineRecipes: Recipe[]){
    removedOrderlineRecipes.forEach(recipe => {
      //should remove it from the shownlist and allrecipes list when it is not in the searched list
      if(!this._searchRecipes.map(r=> r.id).includes(recipe.id)){
        const showIndex = this.shownRecipes.findIndex(r=> r.id === recipe.id);
        const allIndex = this._allRecipes.findIndex(r=> r.id === recipe.id);

        if(showIndex >= 0) this.shownRecipes.splice(showIndex, 1)
        if(allIndex >= 0) this._allRecipes.splice(showIndex, 1)
      }
    });
  }

  filterOnLifestyles(recipes: Recipe[]): Recipe[] {
    if (!recipes || recipes.length == 0) return [];

    if (this.lifestyleFilter && this._lifestyleids && this._lifestyleids.length >0) {
      return recipes.filter( recipe=>{
        //always show the ordered recipes
        if(this._orderRecipes.map(r=> r.id).includes(recipe.id))
          return true;

        return this._lifestyleids.find(l=> 
          recipe.lifestyles.map(r=> r.lifestyleid).includes(l)
        )
      }
      )
    } else {
      return recipes; 
    }
  }
  sortRecipes(recipes: Recipe[]): Recipe[] {
    let _recipes = recipes.sort((a, b) => a.id - b.id);

    if (!_recipes || _recipes.length == 0 && !this._orderlines) {
      return _recipes;
    }

    //sorts _recipes with salessurcharge to the end
    _recipes = _recipes.sort((a, b) => (a.salessurcharge || 0) - (b.salessurcharge || 0))

    if (this._allergens) {
      _recipes = _recipes.sort((a, b) => {
        const acount = a.allergens.map(all => all.name).filter(all => this._allergens.includes(all)).length;
        const bcount = b.allergens.map(all => all.name).filter(all => this._allergens.includes(all)).length;

        if (acount < bcount) { return -1; }
        if (acount > bcount) { return 1; }
        return 0;
      });

    }

    return _recipes;
  }

  sortRecipesOnExpiration(recipes: Recipe[]):Recipe[]{
    return recipes.sort((a,b)=> a.expiredays - b.expiredays)
  }

  swipeLeft() {
    this.recipelistEl.nativeElement.scrollTo({ left: this.scrollPosition -= 200, top: 0, behavior: 'smooth' });
  }

  swipeRight() {
    this.recipelistEl.nativeElement.scrollTo({ left: this.scrollPosition += 200, top: 0, behavior: 'smooth' });
  }
}
